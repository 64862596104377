
export default {
  name: "PageNotFound",
  async asyncData({ $utils, params }) {
    return {
      story: await $utils.content("not-found"),
    };
  },
  head() {
    return this.$utils.head(this.story)
  },
  computed: {
    blok() {
      return this.story?.content;
    },
  },
};
